<template>
	<div class="router-view-wrapper">
			<SettingsPreview
				v-if="logged_user.customer_permission == 'SENDER'"
			/>
			<q-list>
				<!-- EMAIL -->
				<div class="data-wrapper text-left items-start row" v-if="preview_tab_devices_email.condition">
					<div class="col-sm-3 col-lg-3 col-12 flex">
						<q-btn
							round
							flat
							color="dark"
							icon="o_email"
							size="md"
							class="cursor-default no-hover top-btn"
							:ripple="false"
						/>
						<div class="items-baseline flex">{{preview_tab_devices_email.dispatch_label}}</div>
					</div>
					<div class="col-sm-6 col-lg-7 col-12">
						<div class="row">
							<div class="col-sm-4 col-lg-2 col-12">Subject line: </div>
							<div class="col-sm-8 col-lg-10 col-12">{{preview_tab_devices_email.preview_text_subject}}</div>
						</div>
						<div class="row">
							<div class="col-sm-4 col-lg-2 col-12">Content: </div>
							<div class="break-word col-sm-8 col-lg-10 col-12" v-html="preview_tab_devices_email.preview_text"></div>
						</div>
						<div class="row" v-if="include_links_in_email">
							<div class="col-sm-4 col-lg-2 col-12">{{ email_attachments_header }}</div>
							<div class="col-sm-8 col-lg-10 col-12" v-html="email_attachments"></div>
						</div>
					</div>
					<div class="col-sm-3 col-lg-2 col-12"></div>
				</div>
				<!-- SMS -->
				<div v-if="preview_tab_devices_sms.condition" class="data-wrapper text-left items-start row">
					<div class="col-sm-3 col-lg-3 col-12 flex margin-top">
						<q-btn
							round
							flat
							color="dark"
							size="md"
							icon="o_textsms"
							class="cursor-default no-hover top-btn"
							:ripple="false"
						/>
						<div class="items-baseline flex">{{preview_tab_devices_sms.dispatch_label}}</div>
					</div>
					<div class="col-sm-6 col-lg-7 col-12">
						<div class="row">
							<div class="col-sm-4 col-lg-2 col-12">Content: </div>
							<div class="break-word col-sm-8 col-lg-10 col-12" v-html="preview_tab_devices_sms.preview_text"></div>
						</div>
						<div class="row" v-if="include_links_in_sms">
							<div class="col-sm-4 col-lg-2 col-12">{{sms_attachments_header}}</div>
							<div class="col-sm-8 col-lg-10 col-12" v-html="sms_attachments"></div>
						</div>
						<!-- DO NOT DELETE -->
						<!-- <div class="row full-width">
							<div class="col-sm-4 col-12">Characters/ segments: </div>
							<div class="col-sm-8 col-12">
								<SMSCharacterCounter
									class="full-width"
									:content="preview_tab_devices_sms.preview_text"
									:is_content_dynamic="is_content_dynamic"
								/>
							</div>
							<details-button
								v-if="is_content_dynamic"
								class="q-ma-none format"
								:tooltip_content="tooltip_text"
								name="count-sms-charecters"
							/>
						</div> -->
					</div>
					<div class="col-sm-3 col-lg-2 col-12"></div>
				</div>
				<!-- VOICE -->
				<div v-if="use_noticeboard || voice_channel_active">
					<div v-for="preview_dispatch in preview_tab_devices_voice" :key="preview_dispatch.label">
						<div v-if="preview_dispatch.condition" class="data-wrapper text-left items-start row">
							<div class="col-sm-3 col-lg-3 col-12 flex">
								<q-btn
									round
									flat
									color="dark"
									icon="o_call"
									size="md"
									class="cursor-default no-hover top-btn"
									:ripple="false"
								/>
								<div class="items-baseline flex">{{preview_dispatch.dispatch_label}}</div>
							</div>
							<div class="col-sm-6 col-lg-7 col-12">
								<div class="row">
									<div class="col-sm-4 col-lg-2 col-12">Content: </div>
									<div class="break-word col-sm-8 col-lg-10 col-12" v-html="preview_dispatch.preview_text"></div>
								</div>
							</div>
							<!--  style="margin-top: auto; margin-bottom: auto;" -->
							<div class="col-sm-3 col-lg-2 col-12">
								<div v-if="preview_dispatch.button_condition" class="flex justify-end">
									<q-btn
										outline
										rounded
										color="primary"
										size="md"
										@click="playTTS(preview_dispatch.preview_text,tts_voice.name, preview_dispatch.id)"
									>
										<div v-if="!preview_dispatch.spinner_value">
											<q-icon name="volume_up" class="q-mr-sm"/>
											<span>Preview</span>
										</div>
										<q-spinner-bars
											v-else
											color="primary"
										/>
									</q-btn>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- TEAMS -->
				<div v-if="preview_tab_devices_teams.condition" class="data-wrapper text-left items-start row">
					<div class="col-sm-3 col-lg-3 col-12 flex">
						<q-btn
							round
							flat
							color="dark"
							:icon="teamsIcon"
							size="md"
							class="cursor-default no-hover top-btn"
							:ripple="false"
						/>
						<div class="items-baseline flex">{{preview_tab_devices_teams.dispatch_label}}</div>
					</div>
					<div class="col-sm-6 col-lg-7 col-12">
						<div class="row">
							<div class="col-sm-4 col-lg-2 col-12">Content: </div>
							<div class="break-word col-sm-8 col-lg-10 col-12" v-html="preview_tab_devices_teams.preview_text"></div>
						</div>
						<div class="row" v-if="include_links_in_teams">
							<div class="col-sm-4 col-lg-2 col-12">{{teams_attachments_header}}</div>
							<div class="col-sm-8 col-lg-10 col-12" v-html="teams_attachments"></div>
						</div>
					</div>
					<div class="col-sm-3 col-lg-2 col-12"></div>
				</div>
				<!-- Slack -->
				<div v-if="preview_tab_devices_slack.condition" class="data-wrapper text-left items-start row">
					<div class="col-sm-3 col-lg-3 col-12 flex">
						<q-btn
							round
							flat
							color="dark"
							:icon="slackIcon"
							size="md"
							class="cursor-default no-hover top-btn"
							:ripple="false"
						/>
						<div class="items-baseline flex">{{preview_tab_devices_slack.dispatch_label}}</div>
					</div>
					<div class="col-sm-6 col-lg-7 col-12">
						<div class="row">
							<div class="col-sm-4 col-lg-2 col-12">Content: </div>
							<div class="break-word col-sm-8 col-lg-10 col-12" v-html="preview_tab_devices_slack.preview_text"></div>
						</div>
						<div class="row" v-if="include_links_in_slack">
							<div class="col-sm-4 col-lg-2 col-12">{{slack_attachments_header}}</div>
							<div class="col-sm-8 col-lg-10 col-12" v-html="slack_attachments"></div>
						</div>
					</div>
					<div class="col-sm-3 col-lg-2 col-12"></div>
				</div>
			</q-list>
			<ReportingNotification/>
			<SmsSenderName/>
		<FinishTabButtons
			:final_recipients="final_recipients"
			:previews="previews"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PreviewDispatch from '@/components/message_forms/finish_tab/PreviewDispatch.vue';
import ReportingNotification from '@/components/message_forms/finish_tab/ReportingNotification.vue';
import SmsSenderName from '@/components/message_forms/finish_tab/SmsSenderName.vue';
import message_forms_utils from '@/utils/message_forms.js';
import FinishTabButtons from '@/components/message_forms/finish_tab/FinishTabButtons.vue';
import SettingsPreview from '@/components/message_forms/finish_tab/SettingsPreview.vue';
import SMSCharacterCounter from '@/components/utils/SMSCharacterCounter.vue'
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'
import MessageFormAPI from '@/services/api/MessageForm.js';
import PreviewButton from '@/components/datasets/utils/PreviewButton.vue'
import { mdiMicrosoftTeams } from "@quasar/extras/mdi-v5";
import { fabSlack } from "@quasar/extras/fontawesome-v5";

export default {
	name: "MessageFormFinish",
	components: {
		PreviewDispatch,
		ReportingNotification,
		FinishTabButtons,
		SettingsPreview,
		SmsSenderName,
		SMSCharacterCounter,
		DetailsButton,
		PreviewButton
	},
	data(){
		return{
			final_recipients: {},
			voice_channel: false,
			previews: {
				email_subject: "",
				email: "",
				voice: "",
				amd: "",
				sms: "",
				noticeboard: "",
				teams: "",
				slack: "",
			},
			show_machine_spinner_load_amd: false,
			show_machine_spinner_load_live: false,
			show_machine_spinner_noticeboard_preview: false,
		}
	},
	computed:{
		...mapGetters([
			'include_recipient_options',
			'is_multiple_criteria_all',
			'is_single_criteria',
			'is_exclude_recipients',
			'is_exclude_recipients_all',
			'exclude_recipient_options',
			'scenario_type',
			'available_sms_field',
			'current_CG',
			'available_voice_field',
			'use_noticeboard',
			'voice_channel_active',
			'logged_user',
			'scenario',
			'is_email_selected',
			'email_channel_active',
			'sms_channel_active',
			'tts_voice',
			'use_microsoft_teams',
			'use_slack',
			'message_content_teams',
			'message_content_slack',
			'email_sms_attachment_files',
			'include_links_in_email',
			'include_links_in_sms',
			'email_attachment_files',
			'sms_attachment_files',
			'include_links_in_teams',
			'include_links_in_slack',
			'teams_attachment_files',
			'slack_attachment_files'
		]),
		preview_tab_devices_email(){
			return {
				'dispatch_label': 'EMAIL',
				'condition':
					(this.scenario_type === 'broadcast' || this.scenario_type === 'sequence') &&
					this.is_email_selected &&
					this.email_channel_active,
				'preview_text_subject': this.previews.email_subject_preview,
				'preview_text': this.previews.email_preview
			}
		},
		preview_tab_devices_teams(){
			return {
				'dispatch_label': 'Microsoft Teams',
				'condition':
					this.use_microsoft_teams &&
					(this.scenario_type ==='broadcast' || this.scenario_type === 'sequence'),
				'preview_text': this.previews.teams,
			}
		},
		preview_tab_devices_slack(){
			return {
				'dispatch_label': 'Slack',
				'condition':
					this.use_slack &&
					(this.scenario_type ==='broadcast' || this.scenario_type === 'sequence'),
				'preview_text': this.previews.slack,
			}
		},
		preview_tab_devices_sms(){
			return {
				'dispatch_label': 'SMS',
				'condition':
					this.sms_channel &&
					this.available_sms_field &&
					(this.scenario_type ==='broadcast' || this.scenario_type === 'sequence') &&
					this.sms_channel_active,
				'preview_text': this.previews.sms_preview
			}
		},
		preview_tab_devices_voice(){
			return [
				{
					'dispatch_label': 'Voice - live answer',
					'condition':
						(this.voice_channel || this.scenario_type === 'conference') &&
						this.available_voice_field &&
						this.voice_channel_active,
					'preview_text': this.previews.voice_preview_live,
					'button_condition':
						this.voice_channel_active &&
						this.previews.voice_preview_live !== '' &&
						this.previews.voice_preview_live !== '.',
					'id': 'load_live',
					'spinner_value': this.show_machine_spinner_load_live,
				},
				{
					'dispatch_label': 'Voice - answering service',
					'condition':
						(this.voice_channel || this.scenario_type === 'conference') &&
						this.available_voice_field &&
						this.voice_channel_active,
					'preview_text': this.previews.voice_preview_amd,
					'button_condition':
						this.voice_channel_active &&
						this.previews.voice_preview_amd &&
						this.previews.voice_preview_amd.length &&
						this.previews.voice_preview_amd !== '.',
					'id': 'load_amd',
					'spinner_value': this.show_machine_spinner_load_amd,
				},
				{
					'dispatch_label': 'Audio Noticeboard',
					'condition':
						this.use_noticeboard &&
						(this.scenario_type ==='broadcast' || this.scenario_type === 'sequence'),
					'preview_text': this.previews.noticeboard_message_preview,
					'button_condition': this.previews.show_noticeboard_tts_preview && this.use_noticeboard,
					'id': 'noticeboard_preview',
					'spinner_value': this.show_machine_spinner_noticeboard_preview,
				},
			]
		},
		is_content_dynamic: function() {
			return this.current_CG.root_customer_group_permissions.includes('Merge fields');
		},
		tooltip_text: function () {
			return 'SMS characters used is the total number of characters used in your message content, ' +
				'reply options or response links, file links, and automatically generated content (“Please reply” etc).\n\n' +
				'Where merge fields are used, each merge field is calculated as 10 characters. ' +
				'Longer SMS messages are broken into smaller segments ' +
				'by carriers and older handsets may display each segment as an individual SMS.\n\n' +
				'The maximum number of characters per segment and the maximum number of allowable segments ' +
				'depends on whether you are using any special characters.';
		},
		email_attachments () {
			let formatted_attachments = ''
			if (this.email_sms_attachment_files.length) {
				for (let attachment of this.email_sms_attachment_files) {
					if (attachment.attachment_name) {
						 formatted_attachments += attachment.attachment_name + '<br>'
					} else {
						 formatted_attachments += attachment.name + '<br>'
					}
				}
				return formatted_attachments
			} else {
				for (let attachment of this.email_attachment_files) {
					if (attachment.attachment_name) {
						 formatted_attachments += attachment.attachment_name + '<br>'
					} else {
						 formatted_attachments += attachment.name + '<br>'
					}
				}
			}
			return formatted_attachments
		},
		sms_attachments () {
			let formatted_attachments = ''
			if (this.email_sms_attachment_files.length) {
				for (let attachment of this.email_sms_attachment_files) {
					if (attachment.attachment_name) {
						 formatted_attachments += attachment.attachment_name + '<br>'
					} else {
						 formatted_attachments += attachment.name + '<br>'
					}
				}
				return formatted_attachments
			} else {
				for (let attachment of this.sms_attachment_files) {
					if (attachment.attachment_name) {
						 formatted_attachments += attachment.attachment_name + '<br>'
					} else {
						 formatted_attachments += attachment.name + '<br>'
					}
				}
			}
			return formatted_attachments
		},
		teams_attachments () {
			let formatted_attachments = ''
			if (this.email_sms_attachment_files.length) {
				for (let attachment of this.email_sms_attachment_files) {
					if (attachment.attachment_name) {
						 formatted_attachments += attachment.attachment_name + '<br>'
					} else {
						 formatted_attachments += attachment.name + '<br>'
					}
				}
				return formatted_attachments
			} else {
				for (let attachment of this.teams_attachment_files) {
					if (attachment.attachment_name) {
						 formatted_attachments += attachment.attachment_name + '<br>'
					} else {
						 formatted_attachments += attachment.name + '<br>'
					}
				}
			}
			return formatted_attachments
		},
		slack_attachments () {
			let formatted_attachments = ''
			if (this.email_sms_attachment_files.length) {
				for (let attachment of this.email_sms_attachment_files) {
					if (attachment.attachment_name) {
						 formatted_attachments += attachment.attachment_name + '<br>'
					} else {
						 formatted_attachments += attachment.name + '<br>'
					}
				}
				return formatted_attachments
			} else {
				for (let attachment of this.slack_attachment_files) {
					if (attachment.attachment_name) {
						 formatted_attachments += attachment.attachment_name + '<br>'
					} else {
						 formatted_attachments += attachment.name + '<br>'
					}
				}
			}
			return formatted_attachments
		},
		sms_attachments_header () {
			if (this.email_sms_attachment_files.length === 1 || this.sms_attachment_files.length === 1) {
				return 'Attachment:'
			}
			return 'Attachments:'
		},
		email_attachments_header () {
			if (this.email_sms_attachment_files.length === 1 || this.email_attachment_files.length === 1) {
				return 'Attachment:'
			}
			return 'Attachments:'
		},
		teams_attachments_header () {
			if (this.email_sms_attachment_files.length === 1 || this.teams_attachment_files.length === 1) {
				return 'Attachment:'
			}
			return 'Attachments:'
		},
		slack_attachments_header () {
			if (this.email_sms_attachment_files.length === 1 || this.slack_attachment_files.length === 1) {
				return 'Attachment:'
			}
			return 'Attachments:'
		},
		teamsIcon(){
			return mdiMicrosoftTeams
		},
		slackIcon(){
			return fabSlack
		},
	},
	methods: {
		getMessage() {
			const errors = this.$store.getters.errors_per_tab.Finish;
			for (let error of errors){
				this.$q.notify({
					timeout: 6700,
					message: error,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
		},
		createFinalRecipients(){
			let included = [];
			for (let item of JSON.parse(JSON.stringify(this.include_recipient_options))) {
				if (Object.keys(item).length) {
					included.push(item)
				}
			}

			if(!this.is_single_criteria){
				if(this.is_multiple_criteria_all){
					this.final_recipients.include_operator = 'AND';
				}else{
					this.final_recipients.include_operator = 'OR';
				}
			}else{
				this.final_recipients.include_operator = 'OR';
			}

			for(let item of included){
				if(item.recipient_value){
					item.recipient_value = item.recipient_value.map(option => option.id)
				}
			}

			this.final_recipients.include_criteria = included;

			let excluded = [];
			for (let item of JSON.parse(JSON.stringify(this.exclude_recipient_options))) {
				if (Object.keys(item).length) {
					excluded.push(item)
				}
			}

			if(this.is_exclude_recipients){
				if(this.is_exclude_recipients_all){
					this.final_recipients.exclude_operator = 'AND';
				}else{
					this.final_recipients.exclude_operator = 'OR';
				}
			}else{
				this.final_recipients.exclude_operator = 'AND';
			}

			for(let item of excluded){
				if(item.recipient_value){
					item.recipient_value = item.recipient_value.map(option => option.id)
				}
			}
			this.final_recipients.exclude_criteria = JSON.parse(JSON.stringify(excluded));
		},
		async playTTS(text, tts_voice, message_type){
			if(message_type === 'load_amd'){
				text = text.replace('Default amd message from settings', '');
			}
			let message = {};
			if(message_type === 'noticeboard_preview') {
				message = {'message': text, 'tts_voice': this.$store.getters.noticeboard_language.name};
			}else {
				message = {'message': text, 'tts_voice': tts_voice};
			}
			this['show_machine_spinner_' + message_type] = true
			const response = await MessageFormAPI.getTTSAudio(message);
			this['show_machine_spinner_' + message_type] = false
			let audio = new Audio(response);
			audio.play();
		}
	},
	created(){
		this.sms_channel = this.current_CG.root_customer_group_permissions.includes("SMS");
		this.voice_channel = this.current_CG.root_customer_group_permissions.includes("Voice");
		this.createFinalRecipients();
		this.previews = message_forms_utils.createPreview();

		this.getMessage()
	},
	watch: {
		'scenario.devices': {
			deep: true,
			handler() {
				this.previews = message_forms_utils.createPreview();
			}
		},
		include_recipient_options: {
			deep: true,
			handler() {
				this.createFinalRecipients();
			}
		},
		exclude_recipient_options: {
			deep: true,
			handler() {
				this.createFinalRecipients();
			}
		}
	}
}
</script>
<style>
	.no-hover .q-focus-helper {
		display: none;
	}
	.top-btn .q-btn__content{
		align-items: baseline;
	}
</style>
<style lang="scss" scoped>
	.break-word{
		word-break: break-word;
	}
</style>
