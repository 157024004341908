<template>
	<div v-if="is_sms_selected && msg.length > 1" class="text-left">
		<div v-html='checkSmsSenderName()'></div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	name: "SmsSenderName",
	computed: {
		...mapGetters([
			"current_CG",
			'polling_value',
			'sms_two_way_link',
			'scenario',
		]),
	},
	data() {
		return {
			sms_sender_name: '',
			two_way_sms: false,
			msg: '',
			is_sms_selected: false,
		};
	},
	methods: {
		checkSmsSenderName() {
			if (!this.sms_sender_name) {
				this.msg = ' ';
				return this.msg;
			}

			const check_sender_name_validity = /^(?=.*[a-zA-Z &])(?=.*[a-zA-Z0-9])([a-zA-Z0-9 &]{3,11})$/.test(this.sms_sender_name);
			const msg_sufix = 'Some international carriers may overwrite this value to ensure delivery.';
			const valid_msg = `Your SMS messages will be sent using your SMS Sender Name <strong>${this.sms_sender_name}</strong>. ${msg_sufix}`;
			const invalid_msg = `Your SMS sender name is invalid. Your message will be sent from <strong>AlertSMS</strong>. ${msg_sufix}`;

			if (!this.polling_value || this.sms_two_way_link) {
				this.msg = (check_sender_name_validity) ? valid_msg : invalid_msg;
			} else if (this.two_way_sms) {
				if (check_sender_name_validity) {
					this.msg = `Where we detect your recipient’s location does not support sending SMS replies, your messages will be sent using your SMS Sender Name <strong>${this.sms_sender_name}</strong>. ${msg_sufix}`;
				} else {
					this.msg = `Your SMS Sender Name is invalid. Where we detect your recipient’s location does not support sending SMS replies, your messages will be sent from <strong>AlertSMS</strong>. ${msg_sufix}`;
				}
			}
			return this.msg;
		}
	},
	created() {
		const settings = JSON.parse(this.current_CG.customer_group.settings);
		this.sms_sender_name = settings.default_sms_sender_name;
		this.two_way_sms = settings.force_two_way_sms || settings.two_way_sms;
		this.is_sms_selected = this.scenario.devices.some(device => device.dispatch_type == 'SMS');
		this.checkSmsSenderName();
	},
}
</script>
