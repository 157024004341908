<template>
	<q-card class="q-pa-md" style="width: 700px; max-width: 80vw">
		<q-card-section>
			<p>You are sending an outbound message, but you are not updating your inbound Audio Noticeboard. Are you sure you want to do this?</p>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn color="primary" flat label="Cancel" @click="$emit('close')"/>
			<q-btn color="primary" flat label="Yes, I am sure" @click="$emit('confirm')"/>
		</q-card-actions>
	</q-card>
</template>

<script>
export default {
	name: 'AudioNoticeboardModal',
}
</script>
