<template>
	<div class="q-mb-md data-wrapper">
		<q-item tag="label" class="text-left">
			<q-item-section>
				<q-item-label>Send reports link and/or headline stats (permission dependant)?</q-item-label>
			</q-item-section>
			<q-item-section avatar>
				<q-toggle v-model="send_quick_report" />
			</q-item-section>
		</q-item>
		<div v-if="send_quick_report">
			<q-select
				v-model="quick_report_recipients_type"
				:options="recipient_types"
				name="Select recipients"
				label="Select recipients"
			/>
			<q-select
				v-if="quick_report_recipients_type && quick_report_recipients_type.options.length"
				multiple
				use-input
				input-debounce="0"
				v-model="quick_report_recipients"
				:options="filter_options"
				:option-label="(item) => item[label]"
				name="Select recipients"
				@filter="filterFn"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	name: "ReportingNotification",
	data(){
		return{
			filter_options: [],
		}
	},
	computed: {
		...mapGetters([
			"recipient_types",
		]),
		send_quick_report: {
			get() {
				return this.$store.getters.send_quick_report;
			},
			set(val) {
				this.updateSendQuickReport(val);
			},
		},
		quick_report_recipients: {
			get() {
				return this.$store.getters.quick_report_recipients;
			},
			set(val) {
				this.updateQuickReportRecipients(val);
			},
		},
		quick_report_recipients_type: {
			get() {
				return this.$store.getters.quick_report_recipients_type;
			},
			set(val) {
				this.updateQuickReportRecipients([])
				this.updateQuickReportRecipientsType(val);
			},
		},
		label() {
			if (this.$store.getters.quick_report_recipients_type.type === "group") {
				return "name";
			} else if (
				this.$store.getters.quick_report_recipients_type.type === "user"
			) {
				return "address_string";
			} else if (
				this.$store.getters.quick_report_recipients_type.type === "field"
			) {
				return "value";
			}
		},
	},
	methods: {
		...mapActions([
			"updateQuickReportRecipients",
			"updateQuickReportRecipientsType",
			"updateSendQuickReport"
		]),
		filterFn (val, update) {
			if (val === '') {
				update(() => {
					this.filter_options = this.quick_report_recipients_type.options
				})
				return
			}

			update(() => {
				const needle = val.toLowerCase()
				this.filter_options = this.quick_report_recipients_type.options.filter(
					(obj) => obj[this.label].toLowerCase().indexOf(needle) > -1
				)
			})
		}
	},
};
</script>