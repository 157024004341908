import { render, staticRenderFns } from "./MessageFormFinish.vue?vue&type=template&id=76eb29e2&scoped=true&"
import script from "./MessageFormFinish.vue?vue&type=script&lang=js&"
export * from "./MessageFormFinish.vue?vue&type=script&lang=js&"
import style0 from "./MessageFormFinish.vue?vue&type=style&index=0&id=76eb29e2&prod&lang=css&"
import style1 from "./MessageFormFinish.vue?vue&type=style&index=1&id=76eb29e2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76eb29e2",
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpinnerBars from 'quasar/src/components/spinner/QSpinnerBars.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QBtn,QIcon,QSpinnerBars});
