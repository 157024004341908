<template>
	<div class="text-left q-mb-md data-wrapper">
		<div v-if="invocation_method_message"><b>Invocation Method:</b> {{ invocation_method_message }} </div>
		<div v-if="scenario_type_text"><b>Message type:</b> {{ scenario_type_text }}</div>
		<div v-if="scenario_name"><b>Message name:</b> {{ scenario_name }}</div>
		<div v-if="selected_language"><b>System prompt language:</b> {{ selected_language }} </div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'SettingsPreview',
	computed: {
		...mapGetters([
			'is_dashboard',
			'is_phone_invoke',
			'is_incident_manager',
			'is_email_invoke',
			'scenario_type',
			'scenario_name',
			'selected_language'
		])
	},
	created(){
		this.invocation_method_message = "";
		this.scenario_type_text = "";
		let invocation_list = []

		if(this.is_dashboard){
			invocation_list.push('Dashboard')
		}
		if(this.is_phone_invoke){
			invocation_list.push('Phone Invoke')
		}
		if(this.is_email_invoke){
			invocation_list.push('Email Invoke')
		}
		if(this.is_incident_manager){
			invocation_list.push('Incident Manager')
		}

		if (invocation_list.length == 1){
			this.invocation_method_message += invocation_list[0]
		}else{
			for (let item in invocation_list){
				if (item != (invocation_list.length -1)){
					this.invocation_method_message += invocation_list[item] + ', '
				}else{
					this.invocation_method_message += invocation_list[item]
				}
			}
		}
		if(this.scenario_type){
			this.scenario_type_text = this.scenario_type.charAt(0).toUpperCase() + this.scenario_type.slice(1);
		}
	}
}
</script>
