<template>
	<div>
		<div :class="{ 'error': sms_content_data.is_invalid }">
			<span>{{sms_content_data.stats}} </span>
			<span>{{sms_content_data.number_of_segments}}</span>
		</div>
	</div>
</template>

<script>
import Utils from '@/services/utils.js';
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default {
	name: 'SMSCharacterCounter',
	components: {
		DetailsButton,
	},
	props: {
		content: String,
		is_content_dynamic: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		sms_content_data: function () {
			const content = this.is_content_dynamic ? Utils.approximateMessageContent(this.content) : this.content;
			const threshold_data = Utils.calculateMessageSegments(content);
			const number_of_segments = threshold_data.number_of_segments
			const stats = `(${content.length} / ${threshold_data.threshold})`;

			return {
				stats,
				number_of_segments,
				is_invalid: number_of_segments > 5,
			};
		},
	},
}
</script>

<style scoped>
.error {
	color: #b83b22;
}
.format{
	white-space: pre-wrap;
}
</style>