<template>
	<q-item-section v-if="preview_dispatch.condition">
		<div class="row justify-end" v-if="has_sms_content && preview_dispatch.condition">
			<SMSCharacterCounter
				:content="preview_dispatch.preview_text"
				:is_content_dynamic="is_content_dynamic"
			/>
		</div>
		<div class='row full-width items-center'>
			<div class="col-sm-4 col-12 row">
				<q-icon
					size='sm'
					:name="preview_dispatch.class"
				/>
				<div class="q-pl-sm flex items-center">{{ preview_dispatch.dispatch_label }}</div>
			</div>
			<div class="col-sm-8 col-12 border q-pl-sm" v-html="preview_text"></div>
		</div>
		<div class="row justify-end q-mt-md" v-if="preview_dispatch.button_condition">
			<q-btn
				label="Preview"
				outline
				rounded
				color="primary"
				icon="volume_up"
				size="md"
				:loading="show_machine_spinner"
				@click="playTTS(preview_dispatch.preview_text, tts_voice.name, preview_dispatch.id)"
			/>
		</div>
	</q-item-section>
</template>

<script>
import { mapGetters } from 'vuex';
import MessageFormAPI from '@/services/api/MessageForm.js';
import SMSCharacterCounter from '@/components/utils/SMSCharacterCounter.vue'
import Utils from '@/services/utils.js';

export default {
	name: 'PreviewDispatch',
	components: { SMSCharacterCounter },
	props: { preview_dispatch: Object },
	data(){
		return{
			show_machine_spinner: false
		}
	},
	computed: {
		...mapGetters(['tts_voice', 'current_CG']),
		has_sms_content: function () {
			return this.preview_dispatch.dispatch_label === 'SMS' && this.preview_dispatch.preview_text.length;
		},
		is_content_dynamic: function() {
			return this.current_CG.root_customer_group_permissions.includes('Merge fields');
		},
		preview_text: function () {
			return this.has_sms_content ? Utils.parseGSM(this.preview_dispatch.preview_text) : this.preview_dispatch.preview_text;
		}
	},
	methods: {
		async playTTS(text, tts_voice, message_type){
			if(message_type === 'load_amd'){
				text = text.replace('Default amd message from settings', '');
			}
			let message = {};
			if(message_type == 'noticeboard_preview') {
				message = {'message': text, 'tts_voice': this.$store.getters.noticeboard_language.name};
			}else {
				message = {'message': text, 'tts_voice': tts_voice};
			}
			this.show_machine_spinner = true;
			const response = await MessageFormAPI.getTTSAudio(message);
			this.show_machine_spinner = false;
			let audio = new Audio(response);
			audio.play();
		}
	}
}
</script>
<style lang="scss" scoped>
	.border{
		border-left: 3px double $grey-5
	}
</style>