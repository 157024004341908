import { render, staticRenderFns } from "./PreviewDispatch.vue?vue&type=template&id=e270a342&scoped=true&"
import script from "./PreviewDispatch.vue?vue&type=script&lang=js&"
export * from "./PreviewDispatch.vue?vue&type=script&lang=js&"
import style0 from "./PreviewDispatch.vue?vue&type=style&index=0&id=e270a342&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e270a342",
  null
  
)

export default component.exports
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItemSection,QIcon,QBtn});
