<template>
	<q-card class="q-pa-md" style="width: 700px; max-width: 80vw">
		<q-card-section>
			<p v-if="number_recipients !== -1">
				You are about to send this message to {{ number_recipients }} recipient(s).
				Are you sure you want to send this message?
			</p>
			<p v-else>
				<q-spinner size="2rem" color="primary" class="dimmed"/>
			</p>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn color="primary" flat label="Cancel" @click="$emit('close')"/>
			<q-btn
				v-if="logged_user.customer_permission !== 'SENDER'"
				color="primary"
				flat
				label="Save & Send"
				@click="emit('save-and-send')"
			/>
			<q-btn color="primary" flat label="Send Only" @click="emit('send-only')"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
	name: 'RecipientCountModal',
	props: {
		number_recipients: Number
	},
	computed: {
		...mapGetters(['logged_user'])
	},
	methods: {
		emit(event) {
			this.$emit(event);
			this.$emit('close');
		}
	},
}
</script>
